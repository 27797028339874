(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("react"), require("mobx-react"), require("styled-components"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["ElcServiceBus", "HypoContainer", "mobx", "react", "mobx-react", "styled-components", "react-dom"], factory);
	else if(typeof exports === 'object')
		exports["elc-service-ratings-reviews"] = factory(require("ElcServiceBus"), require("HypoContainer"), require("mobx"), require("react"), require("mobx-react"), require("styled-components"), require("react-dom"));
	else
		root["elc-service-ratings-reviews"] = factory(root["ElcServiceBus"], root["ElcRuntime"]["HypoContainer"], root["ElcRuntime"]["mobx"], root["ElcRuntime"]["React"], root["ElcRuntime"]["mobxReact"], root["ElcRuntime"]["styled5"], root["ElcRuntime"]["ReactDom"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__estee_elc_service_bus__, __WEBPACK_EXTERNAL_MODULE_hypo_container__, __WEBPACK_EXTERNAL_MODULE_mobx__, __WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_mobx_react__, __WEBPACK_EXTERNAL_MODULE_styled_components__, __WEBPACK_EXTERNAL_MODULE_react_dom__) {
return 