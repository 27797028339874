import { events, serviceQueries } from '@estee/elc-service-bus';
import { PRODUCT_RATING, PRODUCT_REVIEWS, REVIEW_DETAILS } from '@estee/elc-service-view-names';
import { createServiceManifest } from '@estee/elc-service';
import { IManifestServiceRegistryComp } from '@estee/elc-universal-utils';
import { RatingsReviewsService } from './service-setup/RatingsReviewsService';

const { name, version } = __serviceInfo__;

const serviceViews = [PRODUCT_RATING, PRODUCT_REVIEWS, REVIEW_DETAILS];
const serviceEvents: string[] = [
    events.SPP_POWER_REVIEWS_INIT,
    events.READ_REVIEWS_LINK_CLICKED,
    events.WRITE_REVIEWS_LINK_CLICKED,
    events.PRODUCT_FULL_LOADED
];
const queries: string[] = [serviceQueries.GET_REVIEW_SNIPPET];

export const ServiceManifest: IManifestServiceRegistryComp = createServiceManifest<
    RatingsReviewsService
>(
    {
        views: serviceViews,
        events: serviceEvents,
        queries,
        name,
        version
    },
    () =>
        import(
            /* webpackChunkName: 'core' */
            './service-setup/diContainer'
        ),
    'ratingsReviewsService'
);
